import React from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import SectionOne from "../components/Contact/SectionOne/SectionOne"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

export default function Contact() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()

  }, [])
  return (
    <>
      <Helmet>
        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N5HLTJB');
        `,
          }}
        />
        {/* End Google Tag Manager */}

        <meta charSet="utf-8" />
        <title>FABWORK Contato</title>
        <link rel="canonical" href="https://fab.work/contact/" />
        {/* Global site tag (gtag.js) - Google Ads: 668243363 */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-668243363"
        ></script>
        {/* Event snippet for Enviar formulário de lead conversion page */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            gtag('event', 'conversion', {'send_to': 'AW-668243363/IGwzCMXaj9sCEKOr0r4C'});
            `,
          }}
        />
      </Helmet>

      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          title="googletagmanager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-N5HLTJB"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}

      <Header />
      <SectionOne />
      <Footer />
    </>
  )
}
